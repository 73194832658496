<template>
  <v-app>
    <v-row class="my-10">
      <v-col cols="12" md="4" v-for="item in items" :key="item.id">
        <v-hover>
          <template v-slot:default="{ hover }">
            <router-link :to="{ name: item.link }">
              <v-card
                max-width="400"
                height="auto"
                :elevation="hover ? 24 : 6"
                class="mx-auto pa-5"
                rounded="xl"
                color="primary"
              >
                <div
                  class="d-flex flex-no-wrap justify-space-between align-center"
                >
                  <div>
                    <v-badge
                      :content="item.count.toString()"
                      color="secondary"
                      overlap
                    >
                      <v-card-title class="text-h4 white--text pt-0 pr-2">{{
                        item.title
                      }}</v-card-title>
                    </v-badge>
                    <v-badge
                      v-if="item.title == 'Enquiries'"
                      :content="no_of_treat.toString()"
                      color="secondary"
                      overlap
                    >
                      <v-card-subtitle class="text-h6 white--text py-0"
                        >Treated</v-card-subtitle
                      >
                    </v-badge>
                    <v-badge
                      v-if="item.title == 'Enquiries'"
                      :content="no_of_untreat.toString()"
                      color="secondary"
                      overlap
                    >
                      <v-card-subtitle class="text-h6 white--text py-0"
                        >Not Treated</v-card-subtitle
                      >
                    </v-badge>
                    <v-card-actions class="secondary--text font-weight-black">
                      VIEW
                    </v-card-actions>
                  </div>
                  <v-icon size="120" color="secondary" class="float-left">{{
                    item.icon
                  }}</v-icon>
                </div>
              </v-card>
            </router-link>
          </template>
        </v-hover>
      </v-col>
      <v-col cols="12" md="4" v-if="getAdminLevel == 'super admin'">
        <div>
          <v-hover>
            <template v-slot:default="{ hover }">
              <router-link to="/alladmin">
                <v-card
                  max-width="400"
                  height="auto"
                  :elevation="hover ? 24 : 6"
                  class="mx-auto pa-5"
                  rounded="xl"
                  color="primary"
                >
                  <div
                    class="
                      d-flex
                      flex-no-wrap
                      justify-space-between
                      align-center
                    "
                  >
                    <div>
                      <v-badge
                        :content="count.toString()"
                        color="secondary"
                        overlap
                      >
                        <v-card-title class="text-h4 white--text pt-0 pr-2">
                          Admin
                        </v-card-title>
                      </v-badge>
                      <v-card-actions class="secondary--text font-weight-black">
                        VIEW
                      </v-card-actions>
                    </div>
                    <v-icon size="120" color="secondary" class="float-left">
                      mdi-account-cog-outline
                    </v-icon>
                  </div>
                </v-card>
              </router-link>
            </template>
          </v-hover>
        </div>
      </v-col>
      <v-col cols="12" md="4" v-else>
        <v-hover>
          <template v-slot:default="{ hover }">
            <router-link to="/nadmin">
              <v-card
                max-width="400"
                height="auto"
                :elevation="hover ? 24 : 6"
                class="mx-auto pa-5"
                rounded="xl"
                color="primary"
              >
                <div
                  class="d-flex flex-no-wrap justify-space-between align-center"
                >
                  <div>
                    <v-card-title class="text-h4 white--text pt-0 pr-2">
                      Admin
                    </v-card-title>
                    <v-card-subtitle class="text-h6 white--text pt-0 pr-2">
                      Change username and password
                    </v-card-subtitle>
                    <v-card-actions class="secondary--text font-weight-black">
                      VIEW
                    </v-card-actions>
                  </div>
                  <v-icon size="120" color="secondary" class="float-left">
                    mdi-account-cog-outline
                  </v-icon>
                </div>
              </v-card>
            </router-link>
          </template>
        </v-hover>
      </v-col>
    </v-row>
    <v-main class="mx-2 mb-8">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { apiClient } from "@/services";
export default {
  name: "Dashboard",
  data: () => ({
    drawer: true,
    mini: true,
    no_of_treat: 0,
    no_of_untreat: 0,
    count: 0,
    items: [
      {
        id: 1,
        title: "Members",
        icon: "mdi-account-group-outline",
        link: "Members",
        count: 0,
      },
      {
        id: 2,
        title: "Enquiries",
        icon: "mdi-account-alert",
        link: "Enquiries",
        count: 0,
      },
    ],
  }),
  async created() {
    try {
      let [count, no_of_enquiries, no_of_treat, no_of_untreat, members] =
        await Promise.all([
          apiClient.get("/admins?u=2&count=1"),
          apiClient.get("/enquiries?u=2&count=1"),
          apiClient.get("/enquiries?u=2&count=1&treat=1"),
          apiClient.get("/enquiries?u=2&count=1&treat=0"),
          apiClient.get("/bookclub_members?u=2&count=1"),
        ]);
      if (count.data) {
        this.count = count.data.total;
      } else {
        this.displayMsg(count.data);
      }
      if (no_of_enquiries.data) {
        this.items[1].count = no_of_enquiries.data.total;
      } else {
        this.displayMsg(no_of_enquiries.data);
      }
      if (no_of_treat.data) {
        this.no_of_treat = no_of_treat.data.total;
      } else {
        this.displayMsg(no_of_treat.data);
      }
      if (no_of_untreat.data) {
        this.no_of_untreat = no_of_untreat.data.total;
      } else {
        this.displayMsg(no_of_untreat.data);
      }
      if (members.data) {
        this.items[0].count = +members.data.total;
      } else {
        this.displayMsg(members.data);
      }
    } catch (err) {
      this.displayMsg(err.message, "error");
    }
  },

  computed: {
    ...mapState(["curUser", "counts"]),
    ...mapGetters(["getAdminLevel"]),
  },
};
</script>
